import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    paragraph:{
        '& p':{
            textAlign:'center'
        },
        '& .MuiTypography-root':{
            padding:'0.5rem'
        }
    },  link:{
        '& a':{
            textDecoration:'none'
        },
        '& a:hover':{
            textDecoration:'none'
        }
    },
    flex:{
        display:'flex',
    },
    alignItemsCenter:{
        alignItems:'center'
    },
    justifyContentCenter:{
        justifyContent:'center'
    },
    flexColumn:{
        flexDirection:'column'
    },
    flexRow:{
        flexDirection:'row'
    },
    listItem: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding:'0',
      },
      title:{
          fontSize:'1rem'
      }
}));
export {useStyles};
