import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {useStyles} from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';
import TopicHeader from '../TopicHeader'
import { useDispatch, useSelector } from "react-redux";
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
export default ()=>{
    useTitle(PAGE_VIEWS.WELCOME);
    const state = useSelector(state => state.general);
    const history = useHistory();
    const classes=useStyles();
    return <React.Fragment>
        <div className={clsx(classes.flex,classes.flexColumn,classes.alignItemsCenter,classes.paragraph)}>
            <img src={`/images/logos/${state.activeHotel.id.toLowerCase()}.png`} style={{width:100}}></img>
            <TopicHeader text={state.activeHotel.text}></TopicHeader>
            {
               state.activeHotel && state.activeHotel.welcomeText && state.activeHotel.welcomeText.text&& state.activeHotel.welcomeText.text.length>0 &&
               <React.Fragment> {state.activeHotel.welcomeText.text.map((item,index)=>{
                   return <Typography variant="body2" key={index}>{item}</Typography>
               })}
                <Typography style={{textTransform:'uppercase',fontWeight:'bold'}}>{state.activeHotel.text}</Typography> 
               </React.Fragment>
            }           
        </div>
    </React.Fragment>
}