export const pageTitles = {
    "HOME": {
        showBack: false,
        icon: '',
        title: '',
        isHome: true
    },
    "COVID": {
        showBack: true,
        icon: '',
        title: 'Covid-19 Measures and policies'
    },
    "EVENTS": {
        showBack: true,
        title: 'Meeting and Events',
        icon: ''
    },
    "GUESTBOOK": {
        showBack: true,
        title: 'Guest Book',
        icon: ''
    },
    "NEARBY": {
        showBack: true,
        title: 'Nearest Attractions',
        icon: ''
    },
    "CONTACT": {
        showBack: true,
        title: 'Contact Us',
        icon: ''
    },
    "WELCOME": {
        showBack: true,
        title: 'Welcome',
        icon: ''
    },
    "BARDINING": {
        showBack: true,
        title: 'Bar and Dining',
        icon: ''
    },
    "SOCIAL": {
        showBack: true,
        title: 'Social Media',
        icon: ''
    },
    "VEGANMENU": {
        showBack: true,
        title: 'Vegan Menu',
        icon: ''
    }
}

export const PAGE_VIEWS = {
    "HOME": "HOME",
    "EVENTS": "EVENTS",
    "GUESTBOOK": "GUESTBOOK",
    "NEARBY": "NEARBY",
    "CONTACT": "CONTACT",
    "WELCOME": "WELCOME",
    "BARDINING": "BARDINING",
    "SOCIAL": "SOCIAL",
    "COVID": "COVID",
    "VEGANMENU": "VEGANMENU"
}