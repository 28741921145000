import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {useParams} from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as reqTypes from '../../redux/actionType'
import TopicHeader from '../TopicHeader';
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
import storage from '../../storage'
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '90%',
      flexShrink: 0,
      fontWeight:'500'
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    ExpansionPanelSummary:{

    }
  }));
const UiTable=({source})=>{
    const header=source.find(p=>p.isHeader)
    const rows=source.filter(p=>p.isHeader==false)
    return <TableContainer component={Paper}>
    <Table aria-label="s" style={{marginBottom:'0.5rem',marginTop:'0.5rem'}}>
      <TableHead>
        <TableRow>
            {
              header&&header.item.map((data,index)=>{return  <TableCell key={index}>{data.text}</TableCell>})
            }
        
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row,key) => (
          <TableRow key={key}>
           {
               row.item.map((data,key)=>( <TableCell component="th" scope="row">               
               <Typography variant="body2" style={{fontSize:'0.75rem'}}>{data.text}</Typography>
             </TableCell>
            ))
           }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
}
const GuestBookDetails=()=>{    
  useTitle(PAGE_VIEWS.GUESTBOOK);
  const dispatch=useDispatch();
const classes = useStyles();
const { code } = useParams();
debugger;
const state = useSelector(state => state);
const {general,guestBook}=state;
useEffect(()=>{
  if(guestBook.activeData.item==null){
    if(state.general&&state.general.activeHotel.guestBook && state.general.activeHotel.guestBook.items){
      // if(storage.isInit)return;
      // storage.isInit='Y';
      let activeItem=state.general.activeHotel.guestBook.items.find(p=>p.id.toLowerCase()===code.toLowerCase());
      if(activeItem){
        dispatch({ type: reqTypes.SET_ACTIVE_GUEST_BOOK, payload:{code,item:activeItem}});
      }
    }
  }
 
});
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


const handleTypeData=(data,key)=>{
    if (typeof data === 'string' || data instanceof String)
    {
        return <Typography style={{marginBottom:'0.5rem'}} variant="body2"  key={key}><span dangerouslySetInnerHTML={{__html:data}}></span></Typography> ;
    }
    else {
        if(data.type==="string") return <Typography variant="body2" style={{marginBottom:'0.5rem',marginTop:'0.5rem'}} key={key}><span dangerouslySetInnerHTML={{__html: data.data}}></span></Typography> ;
        if(data.type==="table") return <UiTable source={data.data}></UiTable> ;
    }
}
return  <div className={classes.root}>
    {
         guestBook.activeData && guestBook.activeData.code===code && guestBook.activeData.item &&  <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        
         <TopicHeader text={guestBook.activeData.item.category||""}></TopicHeader>
         {
             guestBook.activeData.item.description&&guestBook.activeData.item.description.map((d,i)=>{
                return <Typography key={i} style={{marginBottom:'0.5rem',padding:'0.5rem'}} variant="body2"><span dangerouslySetInnerHTML={{__html: d}}></span></Typography>
             })
         }
       

        </div>
    }
{
      guestBook.activeData && guestBook.activeData.code===code && guestBook.activeData.item.items&&guestBook.activeData.item.items.length>0&&guestBook.activeData.item.items.map((current,index)=>{
            debugger;
                   return <ExpansionPanel expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <ExpansionPanelSummary
                            className={classes.ExpansionPanelSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                        >
                            <Typography className={classes.heading}><span  dangerouslySetInnerHTML={{__html: current.category}}></span>{}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{display:'flex',flexDirection:'column'}}>
                            {
                                current.description&&current.description.map((detail,key)=>{
                                   return handleTypeData(detail,key)
                                })
                            }
                            
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
        })
    }
</div>
}
export default GuestBookDetails;