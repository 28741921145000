function hotelService(id,callback,file="hotels.json") {
    return function (dispatch) {
      return fetch(`/JSON-SOURCE/${file}`)
        .then(data => data.json(), {cache: "no-cache"})
        .then(data => {
            dispatch(callback(id,data))
        })
        .catch((error) => {
            dispatch(callback(id,[],error))
        });
     
    }
  }
  function guestBookService(id,callback,file="GuestBook.json") {
    return function (dispatch) {
      return fetch(file, {cache: "no-cache"})
        .then(data => data.json())
        .then(data => {
            dispatch(callback(id,data))
        })
        .catch((error) => {
            dispatch(callback(id,[],error))
        });
     
    }
  }
  function dineService(id,callback,file="") {
    return function (dispatch) {
      return fetch(`/JSON-SOURCE/${file}`, {cache: "no-cache"})
        .then(data => data.json())
        .then(data => {
            dispatch(callback(id,data))
        })
        .catch((error) => {
            dispatch(callback(id,[],error))
        });
     
    }
  }
  function nearByService(id,callback,file="") {
    return function (dispatch) {
      return fetch(`/JSON-SOURCE/${file}`, {cache: "no-cache"})
        .then(data => data.json())
        .then(data => {
            dispatch(callback(id,data))
        })
        .catch((error) => {
            dispatch(callback(id,[],error))
        });
     
    }
  }
  function offerService(id,callback) {
    return function (dispatch) {
      return fetch(`https://www.gemhotels.com/api/cms/getalloffers`)
        .then(data => data.json())
        .then(data => {
            dispatch(callback(id,data))
        })
        .catch((error) => {
            dispatch(callback(id,null,error))
        });
     
    }
  }
  function htmlService(id,callback,file="") {
    return function (dispatch) {
      return fetch(`/JSON-SOURCE/${file}`, {cache: "no-cache"})
        .then(data =>   data.text())
        .then(data => {
          // var parser = new DOMParser();
          // var doc = parser.parseFromString(html, "text/html");
          dispatch(callback(id,data))
        })
        .catch((error) => {
            dispatch(callback(id,[],error))
        });
     
    }
  }
  export {hotelService,guestBookService,dineService,nearByService,offerService,htmlService}