import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HomeIcon from '@material-ui/icons/Home';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import MapIcon from '@material-ui/icons/Map';
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from "react-redux";
import {ArrowBack} from  '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
const CustomBottomNavigation = withStyles((theme) => ({
        root: {
                backgroundColor: theme.palette.secondary.main,
                zIndex: '999',
                color: theme.palette.text.primary,
                '& .Mui-selected': {
                        color: '#FFF',
                },
                '& .Mui-selected .MuiSvgIcon-root': {
                        color: '#FFF',
                },
                '& .MuiBottomNavigationAction-root': {
                        color: theme.palette.text.primary,
                }

        }
}))(BottomNavigation);
const useStyles = makeStyles((theme) => ({
        bodyContainer:{
            position:'relative',
            paddingTop:'64px',
            paddingBottom:'64px',
        },
        
    }));
    
const Layout=(props)=>{
        debugger;
        const dispatch = useDispatch();
        const state = useSelector(state => state.general);
        const history = useHistory();
        const classes = useStyles();
        const handleChange = (event, newValue) => {
                dispatch({ type: 'BOTTOM_NAVIGATION', payload:newValue });
                const routeData= state.routes;
               
                if(routeData){
                        let path="/";
                        switch(newValue){
                                case "Home":path=routeData.HOME;break;
                                case "Dining":path=routeData.BARDINING;break;
                                case "Nearby":path=routeData.NEARBY;;break;
                                case "Contact":path=routeData.CONTACT;;break;
                        }
                        history.push(path);
                }
               
         };
         let title=state.activeRouter.title||null;
         if(state.activeRouter.isHome){
                title=state.activeHotel?(state.activeHotel.text||'').toUpperCase():'';   
         }
        return <div style={{ width: '100%' }}> 
          <AppBar>
                <Toolbar>
                      {
                       state.activeRouter.showBack&&<IconButton onClick={()=>{
                                if (history.length > 2) { history.goBack(); }
                                else { history.push(state.routes.HOME); }
                              }} color="secondary" style={{marginRight:'1rem'}} component="span">
                                <ArrowBack />
                        </IconButton>
                      }  
                        {state.activeRouter.isHome&&<img src="https://www.gemhotels.com/images/Home-1/gem-logo-header.png" height="50" style={{marginRight:'1rem'}}></img>}
                        <Typography variant="h6">{title}</Typography>
                </Toolbar>
            </AppBar>
         <div className={classes.bodyContainer}>
         {props.children}
         </div>
          <CustomBottomNavigation  style={{position:'fixed',bottom:0,width:'100%',left:0}} value={state.activeBottomNavigation||'recents'} onChange={handleChange} >
            <BottomNavigationAction label="Home" value="Home" icon={<HomeIcon />} />
            <BottomNavigationAction label="Dining" value="Dining" icon={<LocalDiningIcon />} />
            <BottomNavigationAction label="Nearby" value="Nearby" icon={<MapIcon />} />
            <BottomNavigationAction label="Contact" value="Contact" icon={<ContactSupportIcon />} />
        </CustomBottomNavigation>
        </div>
}
export default Layout;