import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as reqTypes from '../../redux/actionType'
import TopicHeader from '../TopicHeader'
import { useStyles } from '../css'
import clsx from 'clsx'
import { useHistory } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { red } from '@material-ui/core/colors';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import { ButtonBase } from '@material-ui/core';
import { useTitle } from '../hooks';
import { PAGE_VIEWS } from '../constants'

const usePageStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: '1rem',
    maxWidth: '768px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  inline: {
    display: 'inline',
  },
  listRoot: {
    '& .MuiListItemText-primary': {
      fontWeight: 'bold !important'
    }
  }
}));

const CardView = ({ item, key }) => {
  useTitle(PAGE_VIEWS.BARDINING);
  const dispatch = useDispatch();
  const pageClasses = usePageStyles();
  const history = useHistory();
  const state = useSelector(state => state);
  const routeData = state.general.routes;
  const { activeHotel } = state.general;
  const [expanded, setExpanded] = React.useState(true);
  let imagePath = '';
  if (item.isInternalImage) {
    imagePath = `/images/${activeHotel.id.toLowerCase()}/menu/`
  }
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const subheader = item.features && item.features.map((feature, index) => {
    return `${feature.ID}: ${feature.Text}`
  });
  const handleMenuMore = (d, l) => {

    let code = d.id.toLowerCase();
    let sub_code = null;
    switch (l.id) {
      case 'WMENU': sub_code = 'WINE-MENU'.toLowerCase(); break;
      case 'RMMENU': code = 'ROOM-SERVICE-MENU'.toLowerCase(); break;
      case 'TDHMENU': sub_code = 'TDH-MENU'.toLowerCase(); break;
      case 'ATMENU': code = 'AFTER-NOON-TEA-MENU'.toLowerCase(); break;
    }
    debugger;
    if (l.id != "VGMN") {
      dispatch({ type: reqTypes.SET_DINE, payload: { code: null, item: null, menu: null } });
      if (routeData) {
        history.push(`${routeData.BARDINING}/menu/${code}${sub_code ? '/' + sub_code : ''}`);
      }
    }
    else {
      //dispatch({ type: reqTypes.VEGANMENU, payload: { code: null, item: null, menu: null } });
      if (routeData) {
        history.push(`${routeData.VEGANMENU}`);
      }
    }
  }
  return <Card key={key} className={pageClasses.root}>
    <CardHeader
      // action={
      //   <IconButton aria-label="settings" onClick={e => handleMenuMore(item)}>
      //     <MoreVertIcon />
      //   </IconButton>
      // }
      title={item.text}

      subheader={<React.Fragment> {subheader && <Typography variant="body2" color="textSecondary" component="div" style={{ textTransform: "capitalize" }}>
        <div><b>RESTAURANT</b>  </div>
        <Typography variant="body2" color="textSecondary" component="span" style={{ textTransform: "capitalize", paddingLeft: 0 }}>{subheader ? subheader.join(", ").toLowerCase() : ""}</Typography>
      </Typography>}</React.Fragment>}
    />
    <CardMedia
      className={pageClasses.media}
      image={`${imagePath}${item.thumb}`}
      title={item.text}
    />
    <CardContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>

      {/* {
    item.description&&item.description.map((data,index)=>{
      return  <Typography variant="body2" color="textSecondary" component="p">
                {data}
            </Typography>
    })
  } */}
      {
        item.links && item.links.map((menuLink, keyData) => {
          return <ButtonBase onClick={e => handleMenuMore(item, menuLink)} style={{ flexBasis: '100%', margin: '0.5rem' }}>
            <Chip label={menuLink.text} color="secondary" style={{ width: '100%', height: '50px' }} />
          </ButtonBase>
        })
      }

    </CardContent>
    {item.Timinigs && <CardActions disableSpacing>
      <Typography onClick={handleExpandClick} component="span">Opening Hours</Typography>
      <IconButton
        className={clsx(pageClasses.expand, {
          [pageClasses.expandOpen]: expanded,
        })}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </IconButton>
    </CardActions>}
    {item.Timinigs && <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Timing data={item.Timinigs}></Timing>
      </CardContent>
    </Collapse>}
  </Card>

}
const Timing = ({ data }) => {
  const classes = usePageStyles();
  return <React.Fragment>
    <List className={classes.root}>
      {
        data.map((item, index) => {
          return <React.Fragment key={index}>
            <ListItem alignItems="center" style={{ justifyContent: 'center' }}>
              <ListItemText
                className={classes.listRoot}
                style={{ textAlign: 'center', fontWeight: 'bold' }}
                primary={item.Type}
                secondary={
                  <React.Fragment>
                    {
                      item.Time.map((timing, key) => {
                        return <div>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {timing.ID}
                          </Typography> {timing.Text}
                        </div>
                      })
                    }

                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        })
      }

    </List>
  </React.Fragment>
}
const BarDining = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { general, guestBook } = state;
  const diningInfo = general.activeHotel.dinings || null;
  const classes = useStyles();

  return <React.Fragment>
    {
      diningInfo && <div className={clsx(classes.flex, classes.flexColumn, classes.alignItemsCenter, classes.paragraph)}>

        <TopicHeader text={`${general.activeHotel.text} ${diningInfo.text}`}></TopicHeader>
        {/* {
      diningInfo.description&&diningInfo.description.map((item,index)=>{
         return <Typography key={index}>{item}</Typography>
      })
    } */}

        {
          diningInfo.items && diningInfo.items.map((item, index) => {
            return <CardView item={item} key={index}></CardView>
          })
        }
      </div>
    }


  </React.Fragment>
}
export default BarDining;