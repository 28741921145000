import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {useStyles} from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';
import TopicHeader from '../TopicHeader'
import { useDispatch, useSelector } from "react-redux";
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
import * as reqTypes from '../../redux/actionType'
import {htmlService} from '../../services'
import storage from '../../storage'
export default ()=>{
    useTitle(PAGE_VIEWS.COVID);
    const dispatch=useDispatch();
    const state = useSelector(state => state.general);
    const history = useHistory();
    const classes=useStyles();
    useEffect(()=>{
        if(state&&state.covidInfo===null){
          if(storage.isInit)return;
          storage.isInit='Y';
            dispatch(htmlService("",(id,data,error)=>{
                if(!error){
                  return { type: reqTypes.SET_COVID_INFO, payload:data};;
                }
              },"covid.html"))
        }
    })
    return <React.Fragment>
        <div >
          {
              state&&state.covidInfo&&<Typography className="body2" component="div">
                    <div className="body2" dangerouslySetInnerHTML={{__html:state.covidInfo}}></div>
              </Typography>
          }
        </div>
    </React.Fragment>
}