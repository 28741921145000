import React, { useState, useEffect } from 'react';
import * as reqTypes from './redux/actionType'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import Layout from './components/Layout'
import Home from './components/Home'
import Welcome from './components/Welcome'
import { useDispatch, useSelector } from "react-redux";
import { hotelService } from './services'
import GuestBook from './components/GuestBook';
import GuestBookDetail from './components/GuestBook/GuestBookDetail';
import BarDining from './components/BarDining';
import Menu from './components/BarDining/Menu';
import Events from './components/Events';
import MeetingRooms from './components/Events/MeetingRooms';
import NearBy from './components/NearBy';
import Social from './components/Social';
import Contact from './components/Contact';
import VeganMenu from './components/VeganMenu';
import Covid from './components/Covid';
const App = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { hotelCode } = useParams();
  const history = useHistory();

  useEffect(() => {
    let hotel_id = hotelCode || '';
    let paths = history.location.pathname.split("/");
    if (paths.length > 1) hotel_id = paths[1];
    dispatch(hotelService(hotel_id, (id, hotels, error) => {
      if (error == undefined || error == null) {
        hotels = hotels || [];
        debugger;
        let hotel = hotels.find(p => p.id.toLowerCase() === id.toLowerCase());
        const routes = {
          HOME: `/${hotel_id}`,
          COVID: `/${hotel_id}/covid-19-measures-and-policies`,
          WELCOME: `/${hotel_id}/welcome`,
          GUESTBOOK: `/${hotel_id}/guest-book`,
          BARDINING: `/${hotel_id}/bar-dining`,
          MICE: `/${hotel_id}/meeting-and-events`,
          NEARBY: `/${hotel_id}/near-by`,
          SOCIAL: `/${hotel_id}/social`,
          CONTACT: `/${hotel_id}/contact`,
          VEGANMENU: `/${hotel_id}/vegan-menu`
        };

        return { type: reqTypes.SET_HOTEL, payload: { hotel, routes } }
      }
      else {
        return { type: reqTypes.SET_HOTEL, payload: { hotel: {}, routes: null } }
      }
    }));

  }, [])
  const routeData = state.general.routes;
  debugger;
  return <Layout>
    <Route exact path='/:hotelCode' component={Home} />
    {
      routeData && <React.Fragment>
        <Route exact path={routeData.COVID} component={Covid} />
        <Route exact path={routeData.WELCOME} component={Welcome} />
        <Route exact path={routeData.GUESTBOOK} component={GuestBook} />
        <Route exact path={`${routeData.GUESTBOOK}/detail/:code`} component={GuestBookDetail} />
        <Route exact path={routeData.BARDINING} component={BarDining} />
        <Route exact path={`${routeData.BARDINING}/menu/:code`} component={Menu} />
        <Route exact path={`${routeData.BARDINING}/menu/:code/:subcode`} component={Menu} />
        <Route exact path={routeData.MICE} component={Events} />
        <Route exact path={`${routeData.MICE}/rooms`} component={MeetingRooms} />
        <Route exact path={`${routeData.NEARBY}`} component={NearBy} />
        <Route exact path={`${routeData.SOCIAL}`} component={Social} />
        <Route exact path={`${routeData.CONTACT}`} component={Contact} />
        <Route exact path={`${routeData.VEGANMENU}`} component={VeganMenu} />
      </React.Fragment>
    }


  </Layout>
};
export default App;
