import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {useStyles} from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';
import TopicHeader from '../TopicHeader'
import { useDispatch, useSelector } from "react-redux";
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { ButtonBase } from '@material-ui/core';
const pageStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '90%',
      flexShrink: 0,
      fontWeight:'500'
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    ExpansionPanelSummary:{

    }
  }));
const Events=()=>{
  useTitle(PAGE_VIEWS.EVENTS);
    const state = useSelector(state => state);
    const history = useHistory();
    const classes=useStyles();
    const pageClasses=pageStyles();
    const {general}=state;
    const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleMenuMore=(d)=>{
    const routeData= state.general.routes;
    if(routeData){
      history.push(`${routeData.MICE}/rooms`);
    }
    
  }
    return <React.Fragment>
        {general.activeHotel && general.activeHotel.events &&  <div className={clsx(classes.flex,classes.flexColumn,classes.alignItemsCenter,classes.paragraph)}>
             
             <TopicHeader text={general.activeHotel.events.text}></TopicHeader>
             {
                 general.activeHotel.events.description&& general.activeHotel.events.description.map((item,index)=>{
                        return  <Typography variant="body2" key={index}>{item}</Typography>
                 })
             }
                
                           
         </div>
         
        }

{
           general.activeHotel && general.activeHotel.events && general.activeHotel.events.features.items&& general.activeHotel.events.features.items.map((item,index)=>{
             return  <React.Fragment>
                <ExpansionPanel expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                      <ExpansionPanelSummary
                          className={pageClasses.ExpansionPanelSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}bh-content`}
                          id={`panel${index}bh-header`}
                      >
                       <Typography className={pageClasses.heading}><span  dangerouslySetInnerHTML={{__html: item.text}}></span>{}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{display:'flex',flexDirection:'column'}}>
                          {
                              item.items&&item.items.map((detail,key)=>{
                                  return  <Typography variant="body2"><span style={{fontWeight:'400'}}  dangerouslySetInnerHTML={{__html: detail}}></span>{}</Typography>
                              })
                          }
                          
                      </ExpansionPanelDetails>
                      </ExpansionPanel>
                   
                      </React.Fragment>
              })
      }
        <div className={clsx(classes.flex,classes.flexColumn,classes.alignItemsCenter,classes.paragraph)}>
        { general.activeHotel && general.activeHotel.events && general.activeHotel.events.features.items&&<Typography style={{fontSize:'0.75rem'}} ><span  dangerouslySetInnerHTML={{__html: general.activeHotel.events.features.info}}></span>{}</Typography>}   
        </div>
        <ButtonBase style={{width:'100%'}} onClick={e=>handleMenuMore(e)}>
                <Chip label="View Meeting Rooms" color="secondary" style={{width:'100%',height:'50px'}}/>
        </ButtonBase>
       
    </React.Fragment>
}
export default Events;