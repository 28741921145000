import  {LOAD_GUEST_BOOK,SET_ACTIVE_GUEST_BOOK} from '../actionType'
const initialState = {
    items:[],
    activeData:{
      code:'',
      item:null
    }
};

function reducer(state = initialState, action) {
    switch (action.type) {
      case "reset":
        return initialState;
      case LOAD_GUEST_BOOK:
        return {
          ...state,
          items: action.payload
        };
        case SET_ACTIVE_GUEST_BOOK:
          return {
            ...state,
            activeData: action.payload
          };
      default:
        return state;
    }
  }

  export {reducer as default}
