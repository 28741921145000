import React from 'react'
import { Container, Box, Paper, Typography } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Slider from '../Slider';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from '../hooks';
import { PAGE_VIEWS } from '../constants'
import { offerService } from '../../services'
import { useState, useEffect } from 'react';
import * as reqTypes from '../../redux/actionType'
import storage from '../../storage'
const tutorialSteps = [
  {
    label: 'HELLO SUMMER',
    imgPath:
      'https://www.gemhotels.com/images/Offers/hello-summer.webp',
  },
  {
    label: 'REDISCOVER LONDON THIS SUMMER',
    imgPath:
      'https://www.gemhotels.com/images/Offers/7.webp',
  },
  {
    label: 'KEY WORKER RATE',
    imgPath:
      'https://www.gemhotels.com/images/Offers/key-works-rate.webp',
  },
  {
    label: 'WORK AT HOTEL – GET AWAY FROM HOME',
    imgPath:
      'https://www.gemhotels.com/images/Offers/work-at-hotel-get-away-from-home.webp',
  },
  {
    label: 'KEY WORKERS – DAY USE ROOMS',
    imgPath:
      'https://www.gemhotels.com/images/Offers/key-workers-day-use-rooms.webp',
  },
  {
    label: 'PAY WHEN YOU STAY',
    imgPath:
      'https://www.gemhotels.com/images/Offers/spring-sale.webp',
  },
  {
    label: 'MEETINGS & EVENTS SALE',
    imgPath:
      'https://www.gemhotels.com/images/Offers/meetings-events-spring-sale.webp',
  },
  {
    label: 'SAVER DEAL',
    imgPath:
      'https://www.gemhotels.com/images/Offers/getaway-deal.webp',
  },
];
const CustomIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.light
  }
}))(Icon);
const useStyles = makeStyles((theme) => ({
  paperContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '100px'
  },
  iconContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    // fontFamily:"-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'"

  },
  iconHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0.5rem',
    '& span.iconText': {
      fontSize: '12px'
    }
  },
  icon: {
    fontSize: '60px', margin: '0.5rem'
  },
  paper: {
    padding: "1rem",
    marginTop: "-2rem",
    minHeight: '100px',
    zIndex: '100',
    position: 'relative',
    width: '90%',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  useTitle(PAGE_VIEWS.HOME);
  const handleNavigation = (e) => {

    if (e.link) {
      history.push(`${e.link}`)
    }
  }
  const { activeHotel } = state.general;

  useEffect(() => {
    if (state.general.offers === null) {
      // if(storage.isInit)return;
      // storage.isInit="Y";
      dispatch(offerService("", (id, data, error) => {
        if (!error) {
          let offerData = data || [];
          let activeOffers = offerData.filter(p => p.active);
          let sliderData = activeOffers.map((item, index) => {
            return { id: item.id.toLowerCase(), text: item.text, infoText: item.smallDescription, image: item.image.replace(".jpg", ""), isExtrnal: true, link: `https://www.gemhotels.com/special-offers/${item.id.toLowerCase()}` }
          });
          return { type: reqTypes.SET_OFFERS, payload: sliderData };;
        }
      }))
    }
  })
  const classes = useStyles();
  const routeData = state.general.routes;

  const headerSlider = activeHotel && activeHotel.sliders ? activeHotel.sliders.header : null;
  return <React.Fragment>

    {headerSlider && <Slider source={headerSlider} path={`/images/${activeHotel.id.toLowerCase()}/head-slider/`} ></Slider>}
    <div className={classes.paperContainer}>
      <Paper variant="outlined" className={classes.paper} >
        <div className={classes.iconContainer}>

          {
            [{ icon: 'fas fa-hands-helping', text: 'Welcome', link: routeData ? routeData.WELCOME : "" },
            { icon: 'fas fa-book-open', text: 'Guest Book', link: routeData ? routeData.GUESTBOOK : "" },
            { icon: 'far fa-calendar-alt', text: 'Events', link: routeData ? routeData.MICE : "" },
            { icon: 'fas fa-utensils', text: 'Dining & Drinks', link: routeData ? routeData.BARDINING : "" },
            { icon: 'fab fa-instagram', text: 'Social', link: routeData ? routeData.SOCIAL : "" },
            { icon: 'fas fa-map-marked-alt', text: 'Local Guide', link: routeData ? routeData.NEARBY : "" },
            { icon: 'fas fa-headset', text: 'Contact', link: routeData ? routeData.CONTACT : "" }
              // { icon: 'fas fa-leaf', text: 'Vegan Menu', link: routeData ? routeData.VEGANMENU : "" }
            ].map((item, index) => {
              return <div className={classes.iconHolder} onClick={e => handleNavigation(item)}>
                <CustomIcon className={[classes.icon, item.icon].join(" ")} key={item.icon}></CustomIcon>
                {/* <span className="iconText">{item.text}</span> */}
                <Typography component="span" className="iconText">{item.text}</Typography>
              </div>
            })
          }
        </div>

        <div style={{ textAlign: 'left', width: '100%' }}>
          <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Offers
          </Typography>
        </div>
        {/* <div style={{textAlign:'left',width:'100%'}}>
                         <h5 className={classes.text} style={{alignSelf:'flex-start',justifySelf:'flex-start',fontSize:'20px',marginTop:'1rem',marginBottom:'1rem'}}>Offers</h5>
                    </div> */}
        {state.general.offers && <Slider source={state.general.offers} path={`https://www.gemhotels.com/images/Offers/`} ></Slider>}
      </Paper>

    </div>



  </React.Fragment>

}