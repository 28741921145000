import React from 'react'
import {Facebook,Instagram,Twitter,LinkedIn} from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import {useStyles} from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';
import TopicHeader from '../TopicHeader'
import { useDispatch, useSelector } from "react-redux";
import { ButtonBase, Chip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
const CustomIcon = withStyles((theme) => ({
    root: {
            color: theme.palette.secondary.light
    }
}))(Icon);
const Contact=()=>{
    useTitle(PAGE_VIEWS.CONTACT);    
const state = useSelector(state => state.general);
const history = useHistory();
const classes=useStyles();

return <React.Fragment>
    {
        state.activeHotel&&<div className={clsx(classes.flex,classes.flexColumn,classes.alignItemsCenter,classes.paragraph)}>
        <TopicHeader text="Contact Us"></TopicHeader>
        <Card  style={{width:'100%',margin:'1rem'}}>
            <CardContent className={clsx(classes.flex,classes.flexColumn,classes.alignItemsCenter,classes.paragraph)}>
                        <Typography  variant="h6" component="h6"  gutterBottom>
                               { state.activeHotel.text}
                        </Typography>
                        <Typography component="div">
                          {state.activeHotel.address1}
                        </Typography>
                        <Typography component="div">
                          {state.activeHotel.address2}
                        </Typography>
                        <Typography component="div" >
                            <CustomIcon className="fas fa-map-marker-alt" style={{fontSize:'0.75rem',marginRight:'0.5rem'}}></CustomIcon>{state.activeHotel.location}
                        </Typography>
                        <Typography variant="body2" component="div">
                        <CustomIcon className="fas fa-phone-alt" style={{fontSize:'0.75rem',marginRight:'0.5rem'}}></CustomIcon> {state.activeHotel.telephone}
                        </Typography>
                        <Typography variant="body2" component="div">
                        <CustomIcon className="fas fa-fax" style={{fontSize:'0.75rem',marginRight:'0.5rem'}}></CustomIcon>  {state.activeHotel.fax}
                        </Typography>
            </CardContent>
        </Card>
       
    </div>
    }

</React.Fragment>
}
export default Contact;