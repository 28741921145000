import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {useStyles} from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from "react-redux";
import * as reqTypes from '../../redux/actionType'
import {guestBookService} from '../../services'
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useLocation } from 'react-router-dom';
import TopicHeader from '../TopicHeader'
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
export default ()=>{
    useTitle(PAGE_VIEWS.GUESTBOOK);
    const state = useSelector(state => state);
    const location = useLocation();  
    const dispatch=useDispatch();
    console.clear();
    console.log(state);
    debugger;
    useEffect(()=>{
        if(state.general.activeHotel.guestBook&&(state.general.activeHotel.guestBook.items||[]).length>0) return;
        const hotel_id=state.general.activeHotel.id;
    },[])

    const history = useHistory();
    const classes=useStyles();
    debugger;
    const headerText=state.general.activeHotel.text;
    return <React.Fragment>
         <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
         <TopicHeader text={`${headerText} IN-ROOM DIRECTORY`}></TopicHeader>
         </div>
       <List>
           {
              state.general.activeHotel.guestBook&&(state.general.activeHotel.guestBook.items||[]).length>0 && state.general.activeHotel.guestBook.items.map((item,index)=>{
                   return  <ListItem key={index} className={classes.listItem}>
                                <ListItemText   primary={
                                    <Button onClick={e=>{
                                        const code=item.category.toLowerCase().split(" ").join("-");

                                        dispatch({ type: reqTypes.SET_ACTIVE_GUEST_BOOK, payload:{code,item:item}});
                                        const routeData= state.general.routes;
                                        if(routeData){
                                            history.push(`${routeData.GUESTBOOK}/detail/${code}`)
                                        }
                                       
                                    }}  color="primary" style={{width:'100%',padding:0,textAlign:'left'}}>
                                            <Box style={{width:'100%',display:'flex',alignItems:'center'}} component="div" padding={2} boxShadow={2}>
                                                    <Typography style={{flexGrow:1}}>{item.category}</Typography>
                                                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                            </Box>
                                </Button>
                               } />
                            </ListItem>
               })
           }
     
     
    </List>
    </React.Fragment>
}