import { BOTTOM_NAVIGATION, SET_HOTEL,SET_DINE,SET_NEAR_BY ,SET_ACTIVE_ROUTE,SET_TITLE,SET_OFFERS,SET_COVID_INFO} from "../actionType";
import {pageTitles} from '../../components/constants'
const initialState = { 
    activeBottomNavigation:'',
    activeHotel:{
      text:'',
      id:'',
      logo:''
    },
    activeDine:{
      code:null,
      item:null,
      menu:null
    },
    routes:null,
    activeNearBy:null,
    activeRouter:{
      route:'/',
      showBack:true,
      title:null
    },
    offers:null,
    covidInfo:null
};

function reducer(state = initialState, action) {
    switch (action.type) {
      case "reset":
        return initialState;
      case BOTTOM_NAVIGATION:
        return {
          ...state,
          activeBottomNavigation: action.payload
        };
        case SET_HOTEL:
            return {
              ...state,
              activeHotel: action.payload.hotel,
              routes: action.payload.routes,
            };
            case SET_DINE:
              return {
                ...state,
                activeDine: action.payload
              };
              case SET_NEAR_BY:
                return {
                  ...state,
                  activeNearBy: action.payload
                };
                case SET_ACTIVE_ROUTE:
                  return {
                    ...state,
                    activeRouter: action.payload
                  };
                  case SET_TITLE:
                    return {
                      ...state,
                      activeRouter: pageTitles[action.payload]||pageTitles.HOME
                    };
                    case SET_OFFERS:
                      return {
                        ...state,
                        offers: action.payload
                      };
                      case SET_COVID_INFO:
                        return {
                          ...state,
                          covidInfo: action.payload
                        };
      default:
        return state;
    }
  }

  export {reducer as default}
