import React from 'react'
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Typography from '@material-ui/core/Typography';
import {useStyles} from '../css'
import clsx from 'clsx'
import { ButtonBase, Chip } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as reqTypes from '../../redux/actionType'
const pageStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
      },
      img: {
      
        display: 'block',
       maxHeight:'400px',
        overflow: 'hidden',
        width: '100%',
        borderRadius:'0.50rem'
      },
      caption: {
        fontFamily:"-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
        position:'absolute',
        width:'80%',
        maxWidth:'80%',
        textAlign:'center',
        fontWeight:'bold',
        color:'#FFF',
        textShadow: '2px 2px #070127',
        fontSize:'20px'
      },
      text:{
        fontFamily:"-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",  
        fontSize:'15px'
      }
}));
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default({interval,style,path,source=[]})=>{
    const classes=pageStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const globalClasses=useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = source.length;
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStepChange = (step) => {
      setActiveStep(step);
    };
    const getLink=(detail)=>{
    
      return <div  className={clsx(globalClasses.flex,classes.globalClasses,classes.alignItemsCenter,globalClasses.paragraph)}>
                  <Typography  className={globalClasses.link} variant="body2" style={{color:'#FFF'}} ><span style={{ fontWeight: '400',color:'#FFF' }} onClick={e=>{
                    if(detail.isExtrnal) return;
                     dispatch({ type: reqTypes.SET_DINE, payload: { code: null, item: null, menu: null } });
                     history.push(detail.link);
                  }} dangerouslySetInnerHTML={{ __html: detail.link&&detail.isExtrnal?`<a href="${detail.link}" style="color:white">More</a>`:'More' }}></span></Typography>
              </div>
  }
    return  <AutoPlaySwipeableViews
    autoPlay={true}
    interval={interval||5000}
    direction="incremental"
    style={{ width: '100%' }}
    axis={'x'}
    index={activeStep}
    onChangeIndex={handleStepChange}
    enableMouseEvents
>
    {source.map((step, index) => (
        <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
               <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',position:'relative'}}>
                    <picture>
                      <source type="image/webp" srcSet={`${path}${step.image}.webp`} />
                      <img className={classes.img} src={`${path}${step.image}.jpg`} alt={step.text}  />
                    </picture>
                    <div className={classes.caption}>
                    <Typography style={{fontSize:'20px'}}>{step.text}</Typography>
                    <ButtonBase  style={{margin:'0.5rem',width:'100%',maxWidth:'100px'}}>
                      <Chip label={getLink(step)} color="secondary" style={{width:'100%'}}/>
                  </ButtonBase>
                    </div>
                 
                   {step.infoText &&<div style={{position:'absolute',width:'100%',borderBottomRightRadius:'0.5rem',borderBottomLeftRadius:'0.5rem',minHeight:'75px',backgroundColor:'#00000075',bottom:0,left:0,display:'flex',alignItems:'center'}}>
                      <Typography component="span" style={{fontSize:'0.75rem',padding:'0.75rem',color:'#FFF'}}>{step.infoText}</Typography>
                  </div>}   
               </div>    
            ) : null}
        </div>
    ))}
</AutoPlaySwipeableViews>
}