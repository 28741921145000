import React, { useState, useEffect } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import * as reqTypes from '../../redux/actionType'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { dineService } from '../../services'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import TopicHeader from '../TopicHeader'
import { useTitle } from '../hooks';
import { PAGE_VIEWS } from '../constants'
import storage from '../../storage'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '90%',
    flexShrink: 0,
    fontWeight: '500'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  ExpansionPanelSummary: {

  }
}));


const Menu = () => {
  useTitle(PAGE_VIEWS.BARDINING);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { code } = useParams();
  const { subcode } = useParams();
  const state = useSelector(state => state);
  const { general } = state;
  useEffect(() => {
    if (general.activeDine.item == null) {
      if (general && general.activeHotel && general.activeHotel.dinings) {

        // if(storage.isInit)return;
        // storage.isInit='Y';

        let reqCode = code ? code.toUpperCase() : '';
        let searchId = code.toLowerCase();
        switch (reqCode) {
          case 'ROOM-SERVICE-MENU':
          case 'AFTER-NOON-TEA-MENU':
            searchId = reqCode.replace("-MENU", "");
            break;
        }
        let activeItem = general.activeHotel.dinings.items.find(p => p.id.toLowerCase() === searchId.toLowerCase());

        //(code&&code.toUpperCase()==="ROOM-SERVICE-MENU") || 
        if (activeItem) {
          let fileCode = activeItem ? activeItem.id : '';
          switch (reqCode) {
            case 'ROOM-SERVICE-MENU': fileCode = "ROOM-SERVICE"; break;
            case 'AFTER-NOON-TEA-MENU': fileCode = "AFTER-NOON-TEA"; break;
          }
          if (subcode && subcode.toUpperCase() === "TDH-MENU") {
            fileCode = "TDH";
          }
          let fileName = `/${general.activeHotel.id}/${fileCode}-MENU.json`;
          dispatch(dineService(activeItem.id, (id, data, error) => {
            if (fileCode != "ROOM-SERVICE" && fileCode != 'AFTER-NOON-TEA') {
              return { type: reqTypes.SET_DINE, payload: { code, item: activeItem, menu: data } };
            }
            else {
              switch (fileCode) {
                case 'ROOM-SERVICE': return { type: reqTypes.SET_DINE, payload: { code, item: activeItem, menu: { "roomServiceMenu": data } } }; break;
                case 'AFTER-NOON-TEA': return { type: reqTypes.SET_DINE, payload: { code, item: activeItem, menu: { "afterNoonTeaMenu": data } } };; break;
              }
            }
          }, fileName));

        }
      }
    }

  });
  let menuList = [];
  let caption = "";
  debugger;
  switch ((subcode || code).toUpperCase()) {
    case 'WINE-MENU': menuList = general.activeDine.menu ? general.activeDine.menu.wineMenu : [] || []; caption = " - Wine Menu"; break;
    case 'TDH-MENU': menuList = general.activeDine.menu ? general.activeDine.menu.menus : [] || []; caption = " - TABLE D'HOTE MENU"; break;
    case 'ROOM-SERVICE-MENU': menuList = general.activeDine.menu ? general.activeDine.menu.roomServiceMenu : null || null; break;
    case 'AFTER-NOON-TEA-MENU': menuList = general.activeDine.menu ? general.activeDine.menu.afterNoonTeaMenu : null || null; break;
    default: menuList = general.activeDine.menu ? general.activeDine.menu.menus : [] || []; break;
  }
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  debugger;
  const isOtherMenus = code && (code.toUpperCase() == "ROOM-SERVICE-MENU" || code.toUpperCase() == "AFTER-NOON-TEA-MENU");
  return <div className={classes.root}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem', flexDirection: 'column' }}>
      {general.activeDine.item && <TopicHeader text={`${general.activeDine.item.text}${caption} `}></TopicHeader>}
      {general.activeDine.menu && general.activeDine.menu.text && <Typography variant="body2" style={{ textAlign: 'center', padding: '0.5rem' }}>{general.activeDine.menu.text}</Typography>}

      {
        general.activeDine.item && general.activeDine.item.description.map((desc, key) => {
          return <Typography key={key} variant="body2" component="div" style={{ textAlign: 'center' }} ><span dangerouslySetInnerHTML={{ __html: desc }}></span>{ }</Typography>
        })
      }
    </div>

    {
      !isOtherMenus && menuList && menuList.map((current, index) => {

        console.log(current);
        return <ExpansionPanel key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
          <ExpansionPanelSummary
            className={classes.ExpansionPanelSummary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }} className={classes.heading}>
              <Typography component="div" style={{ fontWeight: '500' }} ><span dangerouslySetInnerHTML={{ __html: current.type }}></span>{ }</Typography>
              <Typography component="div" variant="body2" color="textSecondary" style={{ fontSize: '0.8rem' }} ><span dangerouslySetInnerHTML={{ __html: current.desc || '' }}></span>{ }</Typography>
            </div>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column' }}>
            {
              current.items && current.items.map((detail, key) => {

                {
                  return detail.map((item, itemIndex) => {
                    console.log(item);
                    return <React.Fragment key={itemIndex}><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.5rem' }}>
                      <div style={{ flexBasis: '70%' }}>
                        <Typography component="span" variant="body2" color="textSecondary" >
                          <span style={{ display: 'flex', flexDirection: 'column' }} style={{ fontWeight: `${item.isMain == undefined || item.isMain ? 'bold' : ''}` }} dangerouslySetInnerHTML={{ __html: item.text }}></span>{ }
                          <span style={{ display: 'flex', flexDirection: 'column' }} dangerouslySetInnerHTML={{ __html: item.info }}></span>{ }
                        </Typography>
                      </div>
                      <div style={{ flexBasis: '30%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {
                          item.price && item.price.map((pr, index) => {
                            return <Typography key={index} variant="body2" color="textSecondary" component="span" style={{ marginLeft: '0.5rem', fontWeight: `${item.isMain ? 'bold' : ''}` }}> <span dangerouslySetInnerHTML={{ __html: pr || '' }}></span>{ }</Typography>
                          })
                        }
                      </div>
                    </div>
                      {/* <Divider  component="hr" /> */}
                    </React.Fragment>
                  })
                }

              })
            }

          </ExpansionPanelDetails>
        </ExpansionPanel>
      })
    }
    {
      code && code.toUpperCase() == "ROOM-SERVICE-MENU" && menuList && menuList.items.map((current, index) => {

        debugger;
        return <Paper key={index} style={{ paddingTop: current.text ? '0.5rem' : '0px', marginBottom: '0.5rem' }} >
          {current && current.text && <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginBottom: '1rem', flexDirection: 'column' }}>
            <div><Typography style={{ paddingLeft: '0.5rem', fontWeight: '600' }} >{current.text}</Typography></div>

          </div>}
          {
            current.items && current.items.map((ci, ciindex) => {
              debugger;
              return <ExpansionPanel key={ciindex} expanded={expanded === `panel${index}${ciindex}`} onChange={handleChange(`panel${index}${ciindex}`)}>
                <ExpansionPanelSummary
                  className={classes.ExpansionPanelSummary}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}${ciindex}bh-content`}
                  id={`panel${index}${ciindex}bh-header`}
                >
                  {ci.text && <div style={{ display: 'flex', flexDirection: 'column' }} className={classes.heading}>
                    <Typography component="div" style={{ fontWeight: '500' }} ><span dangerouslySetInnerHTML={{ __html: ci.text }}></span>{ }</Typography>
                    <Typography component="div" variant="body2" color="textSecondary" style={{ fontSize: '0.8rem' }} ><span dangerouslySetInnerHTML={{ __html: ci.description || '' }}></span>{ }</Typography>
                  </div>}
                </ExpansionPanelSummary>

                <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column' }}>
                  {
                    ci.items && ci.items.map((item, key) => {

                      {
                        debugger;
                        //return detail.items.map((item,itemIndex)=>{
                        console.log(item);
                        return <React.Fragment key={key}><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.5rem' }}>
                          <div style={{ flexBasis: '70%' }}>
                            <Typography component="span" variant="body2" color="textSecondary" >
                              <span style={{ display: 'flex', flexDirection: 'column' }} style={{ fontWeight: `${item.isMain == undefined || item.isMain ? 'bold' : ''}` }} dangerouslySetInnerHTML={{ __html: item.text }}></span>{ }
                              <span style={{ display: 'flex', flexDirection: 'column' }} dangerouslySetInnerHTML={{ __html: item.info }}></span>{ }
                            </Typography>
                          </div>
                          <div style={{ flexBasis: '30%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {
                              item.price && item.price.map((pr, index) => {
                                return <Typography key={index} variant="body2" color="textSecondary" component="span" style={{ marginLeft: '0.5rem', fontWeight: `${item.isMain ? 'bold' : ''}` }}>{pr} </Typography>
                              })
                            }
                          </div>
                        </div>
                          {/* <Divider  component="hr" /> */}
                        </React.Fragment>
                        // })
                      }

                    })
                  }

                </ExpansionPanelDetails>
              </ExpansionPanel>
            })
          }

        </Paper>


      })
    }
    {
      code && code.toUpperCase() == "AFTER-NOON-TEA-MENU" && menuList && <React.Fragment>
        <Paper style={{ paddingTop: '0.5rem', marginBottom: '0.5rem', padding: '0.75rem' }} >
          {
            menuList.types.map((item, index) => {
              return <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '0.5rem' }}>
                <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: '0.5rem', flexBasis: '70%', fontWeight: 'bold' }}>
                  {item.text}
                  <Typography variant="body2" color="textSecondary" component="div" style={{ fontWeight: 'normal', fontSize: '0.75rem' }}>{item.info || ''}</Typography>
                </Typography>
                <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: '0.5rem', flexBasis: '30%', fontWeight: 'bold', textAlign: 'right' }}>{item.price}</Typography>
              </div>
            })
          }
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '0.5rem' }}>
            <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: '0.5rem', fontWeight: 'normal' }}>{menuList.info}</Typography>

          </div>
        </Paper>
        {
          menuList.menus.map((item, index) => {
            return <Paper key={index} style={{ paddingTop: '0.5rem', marginBottom: '0.5rem', padding: '0.75rem' }} >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '0.5rem', textAlign: 'center' }}>
                <Typography variant="body2" color="textSecondary" component="span" style={{ fontWeight: 'bold' }}>{item.text}</Typography>
                {item.info && <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.75rem', marginBottom: '0.75rem' }} component="span">{item.info || ''}</Typography>}
                {
                  item.items && item.items.map((oth, othIndex) => {
                    return <Typography key={othIndex} variant="body2" color="textSecondary" component="span" >{oth.text}</Typography>
                  })
                }
              </div>
            </Paper>
          })
        }


      </React.Fragment>
    }

    {
      general.activeDine.menu && general.activeDine.menu.footerInfo && <div style={{ padding: '1rem' }}>
        {
          general.activeDine.menu.footerInfo.map((item, index) => {
            return <Typography key={index} variant="body2" component="div" style={{ textAlign: 'center' }} >{item}</Typography>
          })
        }
      </div>
    }
  </div>


}

export default Menu;