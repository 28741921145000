import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useStyles } from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';

import * as reqTypes from '../../redux/actionType'
import { guestBookService } from '../../services'
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { nearByService } from '../../services'

import TopicHeader from '../TopicHeader'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import { useTitle } from '../hooks';
import { PAGE_VIEWS } from '../constants'
import storage from '../../storage'
const pageStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '90%',
        flexShrink: 0,
        fontWeight: '500'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    ExpansionPanelSummary: {

    }
}));

const NearBy = () => {
    useTitle(PAGE_VIEWS.VEGANMENU);
    const state = useSelector(state => state);
    const location = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const pageClasses = pageStyles();
    const { general } = state;
    useEffect(() => {
        if (general && general.activeNearBy === null) {
            //console.log(storage.isInit);
            // if(storage.isInit)return;
            // storage.isInit='Y';
            //console.log(storage.isInit);
            const hotelCode = state.general.activeHotel.id;
            let fileName = `/${hotelCode}/${hotelCode}-VEGAN-MENU.json`;
            debugger;
            dispatch(nearByService(hotelCode, (id, data, error) => {
                debugger;
                return { type: reqTypes.SET_NEAR_BY, payload: data || null };
            }, fileName));
        }
    });
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const { activeNearBy } = general;
    debugger;
    return <React.Fragment>
        {
            activeNearBy && <React.Fragment>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <TopicHeader text={activeNearBy.text}></TopicHeader>
                    {
                        activeNearBy && activeNearBy.description.map((item, index) => {
                            return <Typography key={index} variant="body2" style={{ padding: '0.5rem' }}><span dangerouslySetInnerHTML={{ __html: item || '' }}></span></Typography>
                            // return <span key={index} dangerouslySetInnerHTML={{ __html: item }}>{item}</span>
                        })
                    }
                </div>

                {
                    activeNearBy && activeNearBy.menus && activeNearBy.menus.map((item, index) => {
                        return <ExpansionPanel key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                            <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummary}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }} className={classes.heading}>
                                    <Typography component="div" style={{ fontWeight: '500' }} ><span dangerouslySetInnerHTML={{ __html: item.type }}></span>{ }</Typography>
                                    <Typography component="div" variant="body2" color="textSecondary" style={{ fontSize: '0.8rem' }} ><span dangerouslySetInnerHTML={{ __html: item.desc || '' }}></span>{ }</Typography>
                                </div>
                            </ExpansionPanelSummary>

                            <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                {
                                    item.items && item.items.map((detail, key) => {
                                        {
                                            return detail.map((item, itemIndex) => {
                                                console.log(item);
                                                return <React.Fragment key={itemIndex}><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.5rem' }}>
                                                    <div style={{ flexBasis: '70%' }}>
                                                        <Typography component="span" variant="body2" color="textSecondary" >
                                                            <span style={{ display: 'flex', flexDirection: 'column', fontWeight: `${item.isMain == undefined || item.isMain ? 'bold' : ''}` }} dangerouslySetInnerHTML={{ __html: item.text }}></span>{ }
                                                            <span style={{ display: 'flex', flexDirection: 'column' }} dangerouslySetInnerHTML={{ __html: item.info }}></span>{ }
                                                        </Typography>
                                                    </div>
                                                    <div style={{ flexBasis: '30%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        {
                                                            item.price && item.price.map((pr, index) => {
                                                                return <Typography key={index} variant="body2" color="textSecondary" component="span" style={{ marginLeft: '0.5rem', fontWeight: `${item.isMain ? 'bold' : ''}` }}>{pr} </Typography>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                    {/* <Divider  component="hr" /> */}
                                                </React.Fragment>
                                            })
                                        }

                                    })
                                }

                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    })
                }
            </React.Fragment>
        }

    </React.Fragment>
}
export default NearBy;