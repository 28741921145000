
class AppStorage
{   
    constructor(){
        this.___store_key="g_i_i";
        this.isInit='N';
    }
    get isInit(){
        let val=localStorage.getItem(this.___store_key);
        return val&&val==="Y";
    }
    set isInit(val='Y'){
        localStorage.setItem(this.___store_key,val);
    }
}
export default new AppStorage() ;