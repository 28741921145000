import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {useStyles} from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';
import TopicHeader from '../TopicHeader'
import { useDispatch, useSelector } from "react-redux";
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { ButtonBase, Divider } from '@material-ui/core';
const pageStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '90%',
      flexShrink: 0,
      fontWeight:'500'
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    ExpansionPanelSummary:{

    }
  }));
const MeetingRooms=()=>{
  useTitle(PAGE_VIEWS.EVENTS);
    const state = useSelector(state => state);
    const history = useHistory();
    const classes=useStyles();
    const pageClasses=pageStyles();
    const {general}=state;
    const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
    return <React.Fragment>
        
        <div className={clsx(classes.flex,classes.flexColumn,classes.alignItemsCenter,classes.paragraph)}>
        { general.activeHotel&& general.activeHotel.events && general.activeHotel.events.meetingRooms&&
            <React.Fragment>
                 <TopicHeader text={general.activeHotel.events.meetingRooms.text}></TopicHeader>
                 <div className={clsx(classes.flex,classes.flexRow)} style={{flexWrap:'wrap'}}>
                 {
                     general.activeHotel.events.meetingRooms.features.map((feature,fIndex)=>{
                        return  <Card style={{flexBasis:'45%',margin:'0.5rem'}}>
                        <CardContent>
                            <Typography style={{fontSize:'1rem',fontWeight:'bold'}} color="textSecondary" gutterBottom>
                                {feature.text}
                        </Typography>
                            <Typography variant="body2" component="p">
                            {feature.val}
                            </Typography>
                        </CardContent>
                    </Card>
                     })
                 }
                 </div>
                
                   
            </React.Fragment>
         }   
         {
           general.activeHotel&& general.activeHotel.events && general.activeHotel.events.meetingRooms&& general.activeHotel&& general.activeHotel.events && general.activeHotel.events.meetingRooms.items.map((item,index)=>{
             return  <React.Fragment>
                <ExpansionPanel style={{width:'100%'}} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                      <ExpansionPanelSummary
                          className={pageClasses.ExpansionPanelSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}bh-content`}
                          id={`panel${index}bh-header`}
                      >
                       <Typography className={pageClasses.heading}><span  dangerouslySetInnerHTML={{__html: item.text}}></span>{}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={clsx(classes.flex,classes.flexRow,classes.alignItemsCenter,classes.justifyContentCenter)}>
                      <div className={clsx(classes.flex,classes.flexRow,classes.alignItemsCenter,classes.justifyContentCenter)} style={{flexWrap:'wrap'}}>
                          {
                              item.items&&item.items.map((detail,key)=>{
                                  return  <React.Fragment  key={key}>
                                            <Typography  variant="body2" color="textSecondary" component="span" className={clsx(classes.flex,classes.flexColumn,classes.alignItemsCenter)} style={{flexBasis:'25%'}}>
                                               <span> <b>{detail.text}</b></span>
                                               <span style={{fontSize:'0.75rem'}}> {detail.value===""?'-':detail.value}</span>
                                            </Typography>
                                            </React.Fragment>
                              })
                          }
                           </div>
                      </ExpansionPanelDetails>
                      </ExpansionPanel>
                   
                      </React.Fragment>
              })
      }
        </div>
    </React.Fragment>
}
export default MeetingRooms;