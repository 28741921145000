import React,{useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {useStyles} from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';

import * as reqTypes from '../../redux/actionType'
import {guestBookService} from '../../services'
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {nearByService} from '../../services'

import TopicHeader from '../TopicHeader'
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
import storage from '../../storage'
const pageStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '90%',
      flexShrink: 0,
      fontWeight:'500'
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    ExpansionPanelSummary:{

    }
  }));

const NearBy=()=>{
    useTitle(PAGE_VIEWS.NEARBY);
    const state = useSelector(state => state);
    const location = useLocation();  
    const dispatch=useDispatch();
    const classes=useStyles();
    const pageClasses=pageStyles();
    const {general}=state;
    useEffect(()=>{
        if(general&&general.activeNearBy===null){
            //console.log(storage.isInit);
            // if(storage.isInit)return;
            // storage.isInit='Y';
            //console.log(storage.isInit);
            const hotelCode=state.general.activeHotel.id;
            let fileName=`/${hotelCode}/${hotelCode}-MAP-LOCATION.json`;
            debugger;
            dispatch(nearByService(hotelCode,(id,data,error)=>{
                debugger;
                     return{ type: reqTypes.SET_NEAR_BY, payload:data||null};
             },fileName));
        }
    });
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };
    const {activeNearBy}=general;
    debugger;
    return <React.Fragment>
        {
            activeNearBy && <React.Fragment>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <TopicHeader text={activeNearBy.text}></TopicHeader>
                        {
                            activeNearBy && activeNearBy.description.map((item,index)=>{
                                return <Typography key={index} variant="body2" style={{padding:'0.5rem'}}>{item}</Typography>
                            })
                        }
                    </div>
                   
                    {
                        activeNearBy &&activeNearBy.facilities&& activeNearBy.facilities.map((item, index) => {
                            return  <ExpansionPanel expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                            <ExpansionPanelSummary
                                className={pageClasses.ExpansionPanelSummary}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                            >
                            <Typography className={pageClasses.heading}>{item.text}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{display:'flex',flexDirection:'column'}}>
                               {
                                    item.description&&item.description.map((desc,key)=>{
                                        return  <Typography variant="body2"><span   dangerouslySetInnerHTML={{__html: desc}}></span></Typography>
                                    })
                                }
                                {
                                item.items&&item.items.map((detail,key)=>{
                                    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                                {
                                                    detail.map((data,dataIndex)=>{
                                                            return <Typography  variant="body2" color="textSecondary" style={{flexBasis:'100%',paddingRight:'0.5rem'}}><span style={{fontWeight:'400'}}  dangerouslySetInnerHTML={{__html: data.text}}></span></Typography>
                                                    })
                                                }
                                            </div>
                                })
                                     
                                }
                                
                            </ExpansionPanelDetails>
                            </ExpansionPanel>
                        })
                    }
                    {
                        activeNearBy &&activeNearBy.taxis&& activeNearBy.taxis.map((item, index) => {
                            return  <ExpansionPanel expanded={expanded === `panelb${index}`} onChange={handleChange(`panelb${index}`)}>
                            <ExpansionPanelSummary
                                className={pageClasses.ExpansionPanelSummary}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panelb${index}bh-content`}
                                id={`panelb${index}bh-header`}
                            >
                            <Typography className={pageClasses.heading}>{item.text}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{display:'flex',flexDirection:'column'}}>
                               {
                                    item.description&&item.description.map((desc,key)=>{
                                        return  <Typography ><span   dangerouslySetInnerHTML={{__html: desc}}></span></Typography>
                                    })
                                }
                                {
                                item.items&&item.items.map((detail,key)=>{
                                    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start',marginBottom:'0.5rem',marginTop:'0.5rem' }}>
                                                {
                                                    detail.map((data,dataIndex)=>{
                                                            return <Typography variant="body2" color="textSecondary" style={{flexBasis:'100%',paddingRight:'0.5rem'}}><span style={{fontWeight:'400'}}  dangerouslySetInnerHTML={{__html: data.text}}></span></Typography>
                                                    })
                                                }
                                            </div>
                                })
                                     
                                }
                                
                            </ExpansionPanelDetails>
                            </ExpansionPanel>
                        })
                    }
                   {
                        activeNearBy &&activeNearBy.others&& activeNearBy.others.map((item, index) => {
                            return  <ExpansionPanel expanded={expanded === `panelc${index}`} onChange={handleChange(`panelc${index}`)}>
                            <ExpansionPanelSummary
                                className={pageClasses.ExpansionPanelSummary}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panelc${index}bh-content`}
                                id={`panelc${index}bh-header`}
                            >
                            <Typography className={pageClasses.heading}>{item.text}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{display:'flex',flexDirection:'column'}}>
                              
                               
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: '0.5rem', marginTop: '0.5rem',flexWrap:'wrap' }}>
                                        {
                                            item.items && item.items.map((detail, key) => {
                                                return <Chip key={key}  color="secondary" style={{  margin: '0.5rem' }} label={<Typography  variant="body2" style={{color:'#FFF'}} ><span style={{ fontWeight: '400',color:'#FFF' }} dangerouslySetInnerHTML={{ __html: detail.link?`<a href="${detail.link}" style="color:white">${detail.text}${detail.info?`-${detail.info}`:''}</a>`:detail.text }}></span></Typography>} />
                                                 
                                            })

                                        }
                                    </div>
                               
                                
                            </ExpansionPanelDetails>
                            </ExpansionPanel>
                        })
                    }
                </React.Fragment>
        }
       
    </React.Fragment>
}
export default NearBy;