import React from 'react'
import {Facebook,Instagram,Twitter,LinkedIn} from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import {useStyles} from '../css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';
import TopicHeader from '../TopicHeader'
import { useDispatch, useSelector } from "react-redux";
import { ButtonBase, Chip } from '@material-ui/core';
import {useTitle} from '../hooks';
import {PAGE_VIEWS} from '../constants'
const Social=()=>{
    useTitle(PAGE_VIEWS.SOCIAL);
const state = useSelector(state => state.general);
debugger;
const history = useHistory();
const classes=useStyles();
const getIcon=(icon)=>{
    switch(icon){
        case "instagram":return <Instagram></Instagram>;
        case "facebook":return <Facebook></Facebook>;
        case "twitter":return <Twitter></Twitter>;
        case "linkedin":return <LinkedIn></LinkedIn>;
    }
}
const getLink=(detail)=>{
    
    return <div  className={clsx(classes.flex,classes.flexRow,classes.alignItemsCenter,classes.paragraph)} style={{width:'100%'}}>
               {getIcon(detail.media)} <Typography  className={classes.link} variant="body2" style={{color:'#FFF'}} ><span style={{ fontWeight: '400',color:'#FFF' }} dangerouslySetInnerHTML={{ __html: detail.link?`<a href="${detail.link}" style="color:white">${detail.text}</a>`:detail.text }}></span></Typography>
            </div>
}
return <React.Fragment>
    {
        state.activeHotel&&<div className={clsx(classes.flex,classes.flexColumn,classes.alignItemsCenter,classes.paragraph)}>
        <TopicHeader text={state.activeHotel.social.description}></TopicHeader>
        {
            state.activeHotel.social.items.map((detail,index)=>{
               return <ButtonBase  className={classes.link} key={index} style={{margin:'0.5rem',width:'100%',maxWidth:'300px'}}>
                        <a  href={`${detail.link}`}  style={{width:'100%',height:'50px'}}>
                             <Chip label={getLink(detail)} color="secondary" style={{width:'100%',height:'50px'}}/>
                        </a>
                </ButtonBase>
            })
        }
       
    </div>
    }

</React.Fragment>
}
export default Social;